import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import Tabs from "@atoms/tabs";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { ChartPieIcon, CheckIcon } from "@heroicons/react/outline";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { ProductGraphModalAtom } from "./graph-modal";
import { InputCounter } from "@atoms/input/input-counter";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { ReassortProductLine } from "@features/supplier/types";
import { Base, Info, Title } from "@atoms/text";
import { getReassortTabColumns } from "./reassort-tabs";
import { Input } from "@atoms/input/input-text";
import toast from "react-hot-toast";
import { useAuth } from "@features/general/auth/state/use-auth";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { getCommonProductTooltip } from "@features/utils/format/strings";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { ReasPagination } from "@features/supplier/state/store";

const ReassortPage = () => {
  const { current } = useShopLocations();
  const { user } = useAuth();
  const [tabIndex, setTabIndex] = usePersistedState<number | string>(
    "user",
    "product-reas-index",
    current && current.items ? current!.items[0].codeTypeProd : ""
  );
  const [currentModeGest, setCurrentModeGest] = usePersistedState<string>(
    "user",
    "product-reas-modeGest",
    current && current.items ? current!.items[0].modeGest : "P"
  );

  const [pagination, setPagination] = useRecoilState(ReasPagination);

  const {
    loading,
    reassortLines,
    setReassortLines: changeReassortLines,
    getReassortLines,
    updateReassortLine,
  } = useSuppliers();

  // const setProductGraphModal = useSetRecoilState(ProductGraphModalAtom);
  const [productGraphModal, setProductGraphModal] = useRecoilState(
    ProductGraphModalAtom
  );

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      const nextSelect = document.querySelector("#choix select");
      if (nextSelect) (nextSelect as HTMLSelectElement).focus();
    }, 250);

    //setPagination({ ...pagination, page: 1 });
  }, [tabIndex]);

  const handleLineChange = (
    rowId: string,
    newValue: string | undefined,
    key: string
  ) => {
    changeReassortLines((prevReassortLines) => {
      const index = prevReassortLines.items.findIndex(
        (l: ReassortProductLine) => l.id === rowId
      );
      if (index !== -1) {
        const updatedItem = {
          ...prevReassortLines.items[index],
          [key]: newValue,
        };
        const updatedItems = [
          ...prevReassortLines.items.slice(0, index),
          updatedItem,
          ...prevReassortLines.items.slice(index + 1),
        ];
        return {
          ...prevReassortLines,
          items: updatedItems,
        };
      }
      return prevReassortLines;
    });
  };

  const handleValidLigne = async (p: any) => {
    const res = await updateReassortLine(
      p.id,
      p.ean13,
      p.qte,
      p.panie,
      p.tooltip.codeEtat,
      user?.id || ""
    );
    if (res) {
      toast.success("Ligne mise à jour avec succès");
      const updatedLines = await getReassortLines(
        currentModeGest,
        tabIndex.toString(),
        {
          pageNumber: 1,
          pageSize: 100,
          orderBy: "date_creat", // À modifier
          orderDir: "DESC",
        }
      );
      if (updatedLines) {
        changeReassortLines(updatedLines);
      }
    } else {
      toast.error("Erreur lors de la mise à jour de la ligne");
    }
    setTimeout(() => {
      const nextSelect = document.querySelector("#choix select");
      if (nextSelect) (nextSelect as HTMLSelectElement).focus();
    }, 250);
    // setTimeout(() => {
    //   const nextSelect = document.querySelector("#choix select");
    //   if (nextSelect) (nextSelect as HTMLSelectElement).focus();
    // }, 250);
  };

  const commonCols = [
    {
      title: "Article",
      orderable: true,
      id: "EAN13",
      searchIds: ["article"],
      render: (p, { responsive }) => (
        <div
          className="flex flex-row items-center space-x-4 cursor-pointer"
          onClick={() =>
            navigate(
              ROUTES.Product.replace(/:type/, p.tooltip.modeGest)
                .replace(/:ean/, p.ean13)
                .replace(/:referGestion/, "true")
            )
          }
        >
          <div className="flex flex-col">
            <Base
              className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
              data-tooltip={getCommonProductTooltip(p, p.tooltip)}
            >
              {p.titre || p.designation}
            </Base>
            <Info>{p.ean13}</Info>
            {p.isbn !== "" && <Info>{p.isbn}</Info>}
            {p.idbd !== "" && p.tooltip.modeGest === "P" && (
              <Info>{p.idbd}</Info>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "",
      id: "product_infos_btn",
      orderable: true,
      sticky: true,
      render: (p) => (
        <div className="w-full flex gap-2 justify-center">
          <Button
            className="shrink-0"
            size={"sm"}
            onClick={(e) =>
              setProductGraphModal({
                open: true,
                product: p,
                modeGest: currentModeGest,
                codeTypeProd: tabIndex.toString(),
                pageNumber: reassortLines.pagination.pageNumber,
              })
            }
            theme="secondary"
            data-tooltip="Voir infos ventes"
            data-tooltip-id="my-tooltip-inline"
            data-click="Disponible prochainement !"
            icon={({ className }) => <ChartPieIcon className={className} />}
          />
        </div>
      ),
    },
    {
      title: "Quant.",
      id: "qte",
      sticky: true,
      orderable: true,
      render: (p) => (
        <div style={{ maxWidth: 96, minWidth: 65 }}>
          <InputCounter
            size={"sm"}
            value={p.qte}
            min={0}
            onChange={(newValue) => {
              changeReassortLines((previous) => {
                const tempTab = [...previous.items];
                const reasLineIndex = tempTab.findIndex((l) => l.id === p.id);
                if (reasLineIndex !== -1) {
                  const tempProduct = { ...tempTab[reasLineIndex] };
                  tempProduct.qte = newValue;
                  tempTab[reasLineIndex] = tempProduct;
                  return { ...previous, items: tempTab };
                }
                return previous;
              });
            }}
          />
        </div>
      ),
    },
    {
      title: "Décision",
      id: "choix_decis",
      sticky: true,
      orderable: true,
      render: (p) => (
        <div id="choix" className="flex flex-col gap-1 md:min-w-40">
          <div className="flex gap-1">
            {p.qte > 0 && (
              <Input
                size="sm"
                value={p.panie}
                onChange={(e) => {
                  handleLineChange(p.id, e.target.value, "panie");
                }}
                placeholder="ex: panier..."
              />
            )}
            <Button
              theme={p.qte > 0 ? "valid" : "danger"}
              size="sm"
              data-tooltip="Valider choix"
              onClick={async () => {
                handleValidLigne(p);
                // console.log(p.qte);
                // const res = await updateReassortLine(
                //   p.id,
                //   p.ean13,
                //   p.qte,
                //   p.panie,
                //   p.tooltip.codeEtat,
                //   user?.id || ""
                // );
                // if (res) {
                //   toast.success("Ligne mise à jour avec succès");
                //   const updatedLines = await getReassortLines(
                //     currentModeGest,
                //     tabIndex.toString(),
                //     {
                //       pageNumber: 1,
                //       pageSize: 10,
                //       orderBy: "ean13", // À modifier
                //       orderDir: "DESC",
                //     }
                //   );
                //   if (updatedLines) changeReassortLines(updatedLines);
                // } else {
                //   toast.error("Erreur lors de la mise à jour de la ligne");
                // }
              }}
              icon={({ className }) => <CheckIcon className={className} />}
            >
              {p.qte > 0 ? "" : "Abandon"}
            </Button>
            {/* <Button
                theme="danger"
                size="sm"
                data-tooltip="Annuler choix"
                onClick={() => handleLineChange(p.id, undefined)}
                icon={({ className }) => <XIcon className={className} />}
              /> */}
          </div>
        </div>
      ),
    },
  ] as Column<any>[];

  return (
    <Page loading={loading}>
      <Title>Réassort</Title>
      <div className="flex flex-col grow items-center gap-6">
        <Tabs
          tabs={
            current?.items
              ? current?.items
                  ?.filter((el) => el.modeGest === "L" || el.modeGest === "P")
                  .map((el) => {
                    return { value: el.codeTypeProd, label: el.libTypeProd };
                  })
              : []
          }
          value={tabIndex}
          onChange={async (v) => {
            if (current && current.items) {
              setCurrentModeGest(
                current.items.find((el) => el.codeTypeProd === v)?.modeGest ||
                  "P"
              );
            }
            setTabIndex(v);
          }}
        />
        <div
          key={`${tabIndex} - ${productGraphModal.pageNumber}`}
          className="w-full"
        >
          <Table
            //name={`reas${currentModeGest}`}
            name="reas"
            className="w-full overflow-x-scroll"
            codePres={`reas${currentModeGest}`}
            searchModeEnabled={true}
            scrollable={true}
            //key={`${tabIndex} - ${productGraphModal.pageNumber}`}
            //key={tabIndex}
            data={reassortLines.items}
            total={reassortLines.pagination.totalRows}
            headerPagination={true}
            showPagination={"full"}
            // initialPagination={{
            //   page: productGraphModal.open
            //     ? productGraphModal.pageNumber
            //       ? productGraphModal.pageNumber
            //       : 1
            //     : 1,
            //   perPage: 100,
            //   orderBy: 0,
            //   order: "DESC",
            //   filters: saveFilters,
            // }}
            initialPagination={pagination}
            onRequestData={async ({
              page,
              perPage,
              order,
              orderBy,
              orderById,
              filters,
            }) => {
              let ob = null;
              if (orderById) {
                ob = [
                  ...commonCols,
                  ...getReassortTabColumns(currentModeGest),
                ].find((c) => c.id === orderById)?.searchIds![0];
              }

              const t = [
                ...commonCols,
                ...getReassortTabColumns(currentModeGest),
              ]
                .map((c) => [c.id, c.searchIds])
                .flat(Infinity)
                .filter((el) => el !== undefined);
              let finalF: any = {};
              Object.keys(filters).forEach((k) => {
                if (t.includes(k)) {
                  finalF[k] = filters[k];
                }
              });
              const result = await getReassortLines(
                currentModeGest,
                tabIndex.toString(),
                {
                  pageNumber: productGraphModal.open
                    ? productGraphModal.pageNumber
                      ? productGraphModal.pageNumber
                      : 1
                    : page,

                  pageSize: 100,
                  orderBy:
                    orderBy === 0 ? "date_creat" : ob ? ob : "date_creat",
                  orderDir: order,
                },
                finalF
              );
              // const result = await getReassortLines(
              //   currentModeGest,
              //   tabIndex.toString(),
              //   {
              //     pageNumber: page,

              //     pageSize: perPage,
              //     orderBy:
              //       orderBy === 0 ? "date_creat" : ob ? ob : "date_creat",
              //     orderDir: pagination.order,
              //   },
              //   filters
              // );
              setPagination({
                total: result?.pagination.totalRows || 0,
                page: page,
                perPage: perPage,
                orderBy: orderBy,
                orderById: orderById,
                order: order,
                filters: filters,
              });
              if (result) changeReassortLines(result);
            }}
            onChangeFilters={async (filters) => {
              const t = [
                ...commonCols,
                ...getReassortTabColumns(currentModeGest),
              ]
                .map((c) => [c.id, c.searchIds])
                .flat(Infinity)
                .filter((el) => el !== undefined);

              let finalF: any = {};
              Object.keys(filters).forEach((k) => {
                if (t.includes(k)) {
                  finalF[k] = filters[k];
                }
              });
              setPagination({ ...pagination, filters: finalF });
              const result = await getReassortLines(
                currentModeGest,
                tabIndex.toString(),
                undefined,
                finalF
              );
              if (result) changeReassortLines(result);
            }}
            columns={[...commonCols, ...getReassortTabColumns(currentModeGest)]}
          />
        </div>
      </div>
    </Page>
  );
};

export default ReassortPage;
