import {
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
  BarElement,
  BarController,
  Title,
  Tooltip,
  ArcElement,
  Legend,
  LineController,
  LineElement,
  PointElement,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ProductGraphResponse } from "@features/products/types";
import { useState } from "react";
import { useProduct } from "@features/products/state/use-product";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { InfoSmallBold } from "@atoms/text";
import { ViewListIcon } from "@heroicons/react/outline";
import { Button } from "@atoms/button/button";
import { Table } from "@molecules/table";
import { Column } from "@molecules/table/table";
import { PiChartLine } from "react-icons/pi";
import { toDateISO } from "@features/utils/format/dates";
import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  ArcElement,
  LineElement,
  LineController,
  PointElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

type PeriodPicking = {
  unit: "D" | "W" | "M";
  lib: "J" | "S" | "M";
  range: number;
};

//À rendre modulaire si accepté
export const ProductGraph = (props: {
  //data: ProductGraphResponse[];
  ean: string;
  className?: string;
  //type: "bar" | "line";
}) => {
  const [data, setData] = useState<ProductGraphResponse[]>([]);
  const [tabMode, setTabMode] = useState(false);
  const [timePeriod, setTimePeriod] = useState<PeriodPicking>({
    unit: "D",
    lib: "J",
    range: 7,
  });
  const { getProductGraphVente } = useProduct(
    props.ean,
    undefined,
    undefined,
    false
  );

  useControlledEffect(() => {
    const getGraph = async () => {
      try {
        const res = await getProductGraphVente({
          type: "avs",
          codeCanalVente: "CPT",
          ean: props.ean,
          size: timePeriod.range,
          dwm: timePeriod.unit,
          ean_neuf: "0",
          tri: "ASC",
        });
        if (res) {
          setData(res);
        }
      } catch (error) {
        console.error("Impossible de charger le graph");
      }
    };

    getGraph();
  }, [timePeriod]);

  return (
    <div className="grow flex flex-col gap-2">
      <div className="w-full flex justify-between">
        <div className="flex">
          {(
            [
              { unit: "D", lib: "J", range: 7 },
              { unit: "W", lib: "S", range: 10 },
              { unit: "M", lib: "M", range: 13 },
            ] as PeriodPicking[]
          ).map((el, index) => (
            <DaySelection
              text={el.lib}
              className={
                index === 0 ? "rounded-l-md" : index === 2 ? "rounded-r-md" : ""
              }
              selected={timePeriod.unit === el.unit}
              onClick={() => {
                if (timePeriod.unit !== el.unit) setTimePeriod(el);
              }}
            />
          ))}
        </div>
        <Button
          size="sm"
          theme="secondary"
          data-tooltip={
            tabMode ? "Basculer en vue graphique" : "Basculer en vue tableau"
          }
          onClick={() => setTabMode(!tabMode)}
          icon={({ className }) =>
            tabMode ? (
              <PiChartLine className={className} />
            ) : (
              <ViewListIcon className={className} />
            )
          }
        />
      </div>
      {!tabMode && (
        <Chart
          //style={{ minWidth: "600px", minHeight: "600px" }}
          className={props.className} // Utilisation de w-full pour occuper toute la largeur
          type="line"
          title={`Transactions des ${
            timePeriod.unit === "D"
              ? "7 derniers jours"
              : timePeriod.unit === "W"
              ? "10 dernières semaines"
              : "13 derniers mois"
          } `}
          data={{
            ...data,
            labels: data.map((l) => l.libelle),
            datasets: [
              {
                type: "line" as const,
                label: "Stock",
                borderColor: "#b52218",
                borderWidth: 2,
                fill: false,
                data: data.map((l) => l.qteStock),
              },
              {
                type: "bar" as const,
                label: "Vente",
                borderColor: "#0ac720",
                borderWidth: 2,
                //fill: false,
                data: data.map((l) => l.qteVente),
              },
              {
                type: "bar" as const,
                label: "Achat",
                borderColor: "#150fab",
                borderWidth: 2,
                //fill: false,
                data: data.map((l) => l.qteAchat),
              },
            ],
          }}
          options={{
            plugins: {
              zoom: {
                pan: {
                  enabled: true,
                  mode: "x", // Permet de scroller horizontalement
                },
                zoom: {
                  wheel: {
                    enabled: true,
                  },
                  pinch: {
                    enabled: true,
                  },
                  mode: "x", // Permet de zoomer sur l'axe des X
                },
              },
              title: {
                display: true,
                text: `Transactions des ${
                  timePeriod.unit === "D"
                    ? "7 derniers jours"
                    : timePeriod.unit === "W"
                    ? "10 dernières semaines"
                    : "13 derniers mois"
                } `,
                align: "center",
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
                min: 0,
                max:
                  window.innerWidth > 400
                    ? Math.min(data.length - 1, 12)
                    : Math.min(data.length - 1, 4), // Affiche 10 points maximum à la fois
              },
            },
          }}
        />
      )}

      {tabMode && (
        <Table
          key={timePeriod.unit}
          columns={
            [
              {
                title:
                  timePeriod.unit === "D"
                    ? "Jours"
                    : timePeriod.unit === "W"
                    ? "Semaines"
                    : "Mois",
                render: (p) => toDateISO(p.dateMvt),
              },
              {
                title: "Achat",
                render: (p) => p.qteAchat,
              },
              {
                title: "Vente",
                render: (p) => p.qteVente,
              },
              {
                title: "Stock",
                render: (p) => p.qteStock,
              },
            ] as Column<ProductGraphResponse>[]
          }
          data={data}
        />
      )}
    </div>
  );
};

const DaySelection = (props: {
  text: string;
  className?: string;
  selected?: boolean;
  onClick?: () => void;
}) => {
  return (
    <span
      onClick={props.onClick}
      className={`px-2 cursor-pointer text-blue-600 border-transparent dark:text-white dark:border-sky-700 ${
        props.selected
          ? "bg-blue-300"
          : "bg-blue-100 dark:bg-sky-700 hover:bg-blue-200 dark:hover:bg-sky-800"
      } ${props.className}`}
    >
      <InfoSmallBold className="">{props.text}</InfoSmallBold>
    </span>
  );
};
