import {
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
  BarElement,
  BarController,
  Title,
  Tooltip,
  ArcElement,
  Legend,
  LineController,
  LineElement,
  PointElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ReassortProductLine } from "@features/supplier/types";
import { useProduct } from "@features/products/state/use-product";
import { StockTab } from "./stockTab";
import { ProductGraph } from "./product-graph";
import { PageLoader } from "@atoms/layout/page-loader";
import { Frame } from "@atoms/layout/frame";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  ArcElement,
  LineElement,
  LineController,
  PointElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend
);

//À rendre modulaire si accepté
export const GraphTab = (props: {
  product: ReassortProductLine;
  type: "bar" | "line";
}) => {
  const { loading, product, refresh } = useProduct(
    props.product.ean13,
    undefined,
    props.product.tooltip.modeGest
  );

  useControlledEffect(() => {
    refresh();
  }, [props.product.id]);

  if (loading)
    return (
      <div className="w-full flex justify-center">
        <PageLoader />
      </div>
    );

  return (
    <div className="w-full h-full flex flex-row justify-between items-center gap-8">
      {product && (
        <>
          <Frame className="grow">
            <ProductGraph
              className="min-h-52 overflow-x-auto"
              ean={product.id}
            />
          </Frame>
          <Frame className="grow">
            <StockTab product={product} />
          </Frame>
        </>
      )}
    </div>
  );
};
